import { call, delay, put, takeLeading } from "redux-saga/effects";
import * as SETTINGAPI from "../../api/setting";
import { setLoadingIndicatorVisibility } from "store/actions/Common";

export const types = {};
types.GET_SETTING_CRAWLING_LIST = "GET_SETTING_CRAWLING_LIST";
types.GET_SETTING_CRAWLING_LIST_SUCCESS = "GET_SETTING_CRAWLING_LIST_SUCCESS";

types.SET_SETTING_CRAWLING = "SET_SETTING_CRAWLING";
types.SET_SETTING_CRAWLING_SUCCESS = "SET_SETTING_CRAWLING_SUCCESS";

types.DELETE_SETTING_CRAWLING = "DELETE_SETTING_CRAWLING";
types.DELETE_SETTING_CRAWLING_SUCCESS = "DELETE_SETTING_CRAWLING_SUCCESS";

types.ERROR = "ERROR";

export function* getSettingCrawlingList({ payload }) {
  try {
    const fieldList = yield call(SETTINGAPI.getSettingCrawlingField);
    const crawlingList = yield call(SETTINGAPI.getSettingCrawlingList);
    yield put({
      type: types.GET_SETTING_CRAWLING_LIST_SUCCESS,
      payload: { fieldList: fieldList, crawlingList: crawlingList },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* setSettingCrawling({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const crawlingList = yield call(
      SETTINGAPI.setSettingCrawling,
      payload.keyword,
      payload.regionId,
      payload.quantity,
      payload.isImmediately,
      payload.minSubscriber,
      payload.maxSubscriber,
      payload.startDate
    );
    yield put({
      type: types.SET_SETTING_CRAWLING_SUCCESS,
      payload: { crawlingList: crawlingList },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* deleteSettingCrawling({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const crawlingList = yield call(
      SETTINGAPI.deleteSettingCrawling,
      payload.id
    );
    yield put({
      type: types.DELETE_SETTING_CRAWLING_SUCCESS,
      payload: { crawlingList: crawlingList },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* watchSettingCrawling() {
  yield takeLeading(types.GET_SETTING_CRAWLING_LIST, getSettingCrawlingList);
  yield takeLeading(types.SET_SETTING_CRAWLING, setSettingCrawling);
  yield takeLeading(types.DELETE_SETTING_CRAWLING, deleteSettingCrawling);
}
