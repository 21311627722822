import { types } from 'store/actions/Common';

const initialState = {
  loadingIndicatorVisibility: false,
};

const commonReducer = (state = initialState, action) => {
  switch(action.type) {
    case types.SET_LOADING_INDICATOR_VISIBILITY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;