import { types } from "store/sagas/Campaign";
import { getFormatDate } from "../../utils/dateUtils";
const initialState = {
  campaignList: [
    {
      id: 1,
      name: "캠페인 이름",
      description: "캠페인 설명",
      region: "한국",
      language: "한국어",
      platform: "유튜브",
      manager: "장은진",
      status: 1,
      start_date: "2021-04-21",
      end_date: "2021-04-21",
      category: "게임",
      thumbnail_url: "썸네일 이미지 url",
      published_at: "2021-04-21",
    },
  ],
  field: {
    regions: [
      {
        is_deleted: false,
        code: "JP",
        name: "일본",
        id: 2,
      },
    ],
    languages: [
      {
        is_deleted: false,
        code: "en",
        name: "영어",
        id: 2,
      },
    ],
    categories: [
      {
        is_deleted: false,
        name: "뷰티",
        id: 2,
      },
    ],
    platforms: [
      {
        id: 2,
        is_deleted: false,
        name: "페이스북",
      },
    ],
    managers: [
      {
        role: "MANAGER",
        name: "개발팀",
        username: "dev",
        id: 4,
        password:
          "$2b$12$BwuFTRfHhqL5zW7NmZyrDeRWVn47KoBrJSbIQj6F91IQy.tIJiUCG",
      },
    ],
    advertisers: [
      {
        role: "ADVERTISER",
        name: "라인게임즈#2",
        username: "lg_02",
        id: 3,
        password:
          "$2b$12$ISYHF17mcknHPBJlRy0ElOKla36LF5gMbnzBiuZDyD1K.1PoqJhKC",
      },
    ],
  },
  info: {
    name: "캠페인 이름",
    region: 0,
    language: 0,
    platform: 0,
    manager_id: 0,
    advertiser_id: 0,
    status: 1,
    start_date: getFormatDate(new Date()),
    end_date: getFormatDate(new Date()),
    category: 0,
    description: "설명입니다.",
  },
  influencers: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CAMPAIGN_LIST_SUCCESS:
      return { ...state, campaignList: action.payload.campaignList };
    case types.GET_FEILD_SUCCESS:
      return { ...state, field: action.payload.field };
    case types.GET_CAMPAIGN_INFO_SUCCESS:
      return { ...state, info: action.payload.info };
    case types.GET_CAMPAIGN_INFLUENCER_SUCCESS:
      return { ...state, influencers: action.payload.influencers };
    default:
      return state;
  }
};

export default userReducer;
