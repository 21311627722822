import { put, takeLeading } from "redux-saga/effects";
import { setLoadingIndicatorVisibility } from "store/actions/Common";

export const types = {};
types.SET_USER = "SET_USER";
types.ERROR = "ERROR";
types.LOGOUT = "LOGOUT";

export function* error({ payload }) {
  yield put(setLoadingIndicatorVisibility(false));
  try {
    const errCode = payload.errCode;
    switch (errCode) {
      case 401:
        yield put({ type: types.SET_USER });
        break;
      case 404:
        window.alert("유효하지 않은 데이터 입니다.");
        break;

      default:
        throw Error("error code is invalid, errorCode: ", errCode);
    }
  } catch (err) {
    console.log("유효하지 않은 접근입니다.");
  }
}

export function* watchError() {
  yield takeLeading(types.ERROR, error);
}
