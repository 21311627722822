import { types } from "../sagas/Report";

export const initialState = {
  result: [],
};

const contentsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REPORT_LIST_SUCCESS:
      return {
        result: action.payload,
      };

    case types.GET_REPORT_LIST_ERROR:
      return {
        initialState,
      };

    default:
      return state;
  }
};

export default contentsListReducer;
