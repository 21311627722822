import { call, put, takeLeading } from "redux-saga/effects";
import * as LOGINAPI from "../../api/logIn";

export const types = {};
types.LOGIN = "LOGIN";
types.LOGIN_SUCCESS = "LOGIN_SUCCESS";

types.SET_USER = "SET_USER";
types.SET_USER_SUCCESS = "SET_USER_SUCCESS";

types.LOGOUT = "LOGOUT";
types.ERROR = "ERROR";

export function* login({ payload }) {
  try {
    const { access_token, refresh_token, user_name, user_role } = yield call(
      () => LOGINAPI.onLogin(payload)
    );
    LOGINAPI.setAccessToken(access_token);
    LOGINAPI.setRefreshToken(refresh_token);
    yield put({
      type: types.LOGIN_SUCCESS,
      payload: { username: user_name, userRoll: user_role },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
    window.alert("로그인이 유효하지 않습니다. 다시 시도해 주세요");
  }
}

export function* logout() {
  LOGINAPI.onLogout();
}

export function* setuser() {
  try {
    const { access_token, refresh_token, user_name, user_role } = yield call(
      LOGINAPI.onRefresh
    );
    LOGINAPI.setAccessToken(access_token);
    LOGINAPI.setRefreshToken(refresh_token);
    yield put({
      type: types.SET_USER_SUCCESS,
      payload: { username: user_name, userRoll: user_role },
    });
  } catch (err) {
    yield put({ type: types.LOGOUT });
  }
}

export function* watchLogin() {
  yield takeLeading(types.LOGIN, login);
  yield takeLeading(types.LOGOUT, logout);
  yield takeLeading(types.SET_USER, setuser);
}
