import React from 'react';

const Footer = () => {
  return (
    <div className='footer'>
      <div className='foot-list'>
        <span>주식회사 네이콘</span>
        <span>Tel.070-4742-5809</span>
        <span>support@neicon.net</span>
        <span>서울시 강남구 테헤란로2길 27 비전타워 10층</span>
      </div>
      <div className='copyright'>Copyright © 2020. NEICON. All rights reserved.</div>
    </div>
  )
};

export default Footer;