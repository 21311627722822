import { types } from "store/sagas/Influencer";
import SearchListInit from "data/influencer/search";
import DetailInit from "data/influencer/detail";
import FieldInit from "data/influencer/field";
import InfoInit from "data/influencer/info";
const initialState = {
  influencerList: SearchListInit,
  influencerDetail: DetailInit,
  field: FieldInit,
  fieldFilter: FieldInit,
  info: InfoInit,
  allPage: 1,
  currentPage: 1,
};

const influencerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INFLUENCER_FIELD_FILTER_SUCCESS:
      return {
        ...state,
        fieldFilter: action.payload.fieldFilter,
      };
    case types.GET_INFLUENCER_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        influencerList: action.payload.influencerList,
        allPage: action.payload.allPage,
        currentPage: action.payload.currentPage,
      };
    case types.GET_INFLUENCER_DETAIL_SUCCESS:
      return {
        ...state,
        influencerDetail: action.payload.influencerDetail,
      };
    case types.GET_INFLUENCER_REGIST_SUCCESS:
      return {
        ...state,
        field: action.payload.field,
        info: action.payload.info,
      };
    case types.GET_INFLUENCER_EDIT_SUCCESS:
      return {
        ...state,
        field: action.payload.field,
        info: action.payload.info,
      };
    default:
      return state;
  }
};

export default influencerReducer;
