import { combineReducers } from "redux";
import user from "store/reducers/User";
import common from "store/reducers/Common";
import campaign from "store/reducers/Campaign";
import influencer from "store/reducers/Influencer";
import influencerCandidate from "store/reducers/InfluencerCandidate";
import report from "store/reducers/Report";
import error from "store/reducers/Error";
import settingField from "store/reducers/SettingField";
import settingCrawling from "store/reducers/SettingCrawling";

const rootReducer = combineReducers({
  user,
  common,
  campaign,
  influencer,
  influencerCandidate,
  report,
  error,
  settingField,
  settingCrawling,
});

export default rootReducer;
