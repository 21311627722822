import axios from "axios";
import { HOST } from "./host";

//#region list page
//캠페인 리스트 받아오기
export const getCampaignList = async () => {
  const response = await axios.get(`https://${HOST}/campaign`);
  return response.data;
};
//#endregion

//#region info page
export const deleteCampaign = async (campaignId) => {
  const response = await axios.delete(
    `https://${HOST}/campaign/${campaignId}`,
    { campaign_id: campaignId }
  );
  return response.data;
};

//캠페인 - info field 값 받아오기
export const getCampaignField = async () => {
  const response = await axios.get(`https://${HOST}/campaign/field`);
  return response.data;
};

//캠페인 - info 값 받아오기
export const getCampaignInfo = async (campaignId) => {
  const response = await axios.get(`https://${HOST}/campaign/${campaignId}`);
  return response.data;
};

//캠페인 - info 값 create
export const postCreateCampaignInfo = async (data) => {
  const response = await axios.post(`https://${HOST}/campaign`, data);
  return response.data;
};

//캠페인 - info 값 edit  campaign id
export const postEditCampaignInfo = async (campaignId, data) => {
  const response = await axios.post(
    `https://${HOST}/campaign/${campaignId}`,
    data
  );
  return response.data;
};
//#endregion

//#region influencer page
//캠페인 - influencer 값 받아오기
export const getCampaignInfluecner = async (campaignId) => {
  const response = await axios.get(
    `https://${HOST}/campaign/${campaignId}/influencer`
  );
  return response.data;
};

//캠페인 - influencer 등록 campaign id
export const postCampaignInfluecner = async (
  campaignId,
  channelId,
  nickName
) => {
  const response = await axios.post(
    `https://${HOST}/campaign/${campaignId}/influencer`,
    {
      campaign_id: campaignId,
      channel_id: channelId,
      influencer_nickname: nickName,
    }
  );
  return response.data;
};

//캠페인 - influencer 삭제 campaign id, influencer id
export const deleteCampaignInfluecner = async (campaignId, channelId) => {
  const response = await axios.delete(
    `https://${HOST}/campaign/${campaignId}/influencer`,
    {
      data: {
        campaign_id: campaignId,
        channel_id: channelId,
      },
      withCredentials: true,
    }
  );
  return response.data;
};

//캠페인 - influencer 콘텐츠 업로드 campaign id, channel id, contents id
export const postCampaignInfluecnerContents = async (
  campaignId,
  channelId,
  videoId
) => {
  const response = await axios.post(
    `https://${HOST}/campaign/${campaignId}/influencer/video`,
    {
      campaign_id: campaignId,
      channel_id: channelId,
      video_id: videoId,
    }
  );
  return response.data;
};

//캠페인 - influencer 콘텐츠 삭제 campaign id, channel id, contents id
export const deleteCampaignInfluecnerContents = async (
  campaignId,
  channelId,
  videoId
) => {
  console.log(campaignId, channelId, videoId);
  const response = await axios.delete(
    `https://${HOST}/campaign/${campaignId}/influencer/video`,
    {
      data: {
        campaign_id: campaignId,
        channel_id: channelId,
        video_id: videoId,
      },
      withCredentials: true,
    }
  );

  return response.data;
};

//#endregion
