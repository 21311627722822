import React from "react";
const IntroPage = React.lazy(() => import("pages/intro"));
const RegistSuccessPage = React.lazy(() => import("pages/registSuccess"));
const LoginPage = React.lazy(() => import("pages/login"));
const CampaignListPage = React.lazy(() => import("pages/campaign-list"));
const CampaignRegistInfoPage = React.lazy(() =>
  import("pages/campaign-regist-info")
);
const CampaignRegistInfoSelfPage = React.lazy(() =>
  import("pages/campaign-regist-info-self")
);
const CampaignRegistInfluencerPage = React.lazy(() =>
  import("pages/campaign-regist-influencer")
);
const ReportPage = React.lazy(() => import("pages/report"));
const InfluencerListPage = React.lazy(() => import("pages/influencer-list"));
const InfluencerRegistPage = React.lazy(() =>
  import("pages/influencer-regist")
);
const InfluencerRegistSelfPage = React.lazy(() =>
  import("pages/influencer-regist-self")
);
const InfluencerCandidatePage = React.lazy(() =>
  import("pages/influencer-candidate")
);
const InfluencerBlackPage = React.lazy(() => import("pages/influencer-black"));
const SettingFieldPage = React.lazy(() => import("pages/setting-field"));
const SettingCrawlingPage = React.lazy(() => import("pages/setting-crawling"));

const routes = [
  {
    name: "influencer regist page",
    path: "/influencer/black",
    component: InfluencerBlackPage,
  },
  {
    name: "influencer regist page",
    path: "/influencer/candidate",
    component: InfluencerCandidatePage,
  },
  {
    name: "influencer regist page",
    path: "/influencer/regist/:id",
    component: InfluencerRegistPage,
  },
  {
    name: "influencer regist page",
    path: "/influencer-regist-self",
    component: InfluencerRegistSelfPage,
  },
  {
    name: "influencer regist page",
    path: "/influencer/regist",
    component: InfluencerRegistPage,
    isExact: true,
  },
  {
    name: "influencer list page",
    path: "/influencer/list",
    component: InfluencerListPage,
  },
  {
    name: "setting crawling page",
    path: "/setting/crawling",
    component: SettingCrawlingPage,
  },
  {
    name: "setting field page",
    path: "/setting/field",
    component: SettingFieldPage,
  },
  {
    name: "campaign regist page",
    path: "/campaign-regist-self",
    component: CampaignRegistInfoSelfPage,
  },
  {
    name: "campaign page",
    path: "/campaign/regist-info/:id",
    component: CampaignRegistInfoPage,
  },
  {
    name: "campaign page",
    path: "/campaign/regist-info",
    component: CampaignRegistInfoPage,
    isExact: true,
  },
  {
    name: "campaign page",
    path: "/campaign/regist-influencer/:id",
    component: CampaignRegistInfluencerPage,
  },
  {
    name: "campaign page",
    path: "/campaign/regist-influencer",
    component: CampaignRegistInfluencerPage,
    isExact: true,
  },
  {
    name: "campaign page",
    path: "/campaign/list",
    component: CampaignListPage,
  },
  {
    name: "report page",
    path: "/report",
    component: ReportPage,
  },
  {
    name: "login page",
    path: "/login",
    component: LoginPage,
  },
  {
    name: "influencer regist success page",
    path: "/success",
    component: RegistSuccessPage,
  },
  {
    name: "intro page",
    path: "/intro",
    component: IntroPage,
  },
  {
    name: "intro page",
    path: "/",
    component: IntroPage,
    isExact: true,
  },
];

export default routes;
