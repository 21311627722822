import { call, put, takeLeading, select, delay } from "redux-saga/effects";
import { setLoadingIndicatorVisibility } from "store/actions/Common";
import * as INFLUENCERAPI from "api/influencer";

export const types = {};
types.GET_INFLUENCER_FIELD_FILTER = "GET_INFLUENCER_FIELD_FILTER";
types.GET_INFLUENCER_FIELD_FILTER_SUCCESS =
  "GET_INFLUENCER_FIELD_FILTER_SUCCESS";

types.GET_INFLUENCER_SEARCH_LIST = "GET_INFLUENCER_SEARCH_LIST";
types.GET_INFLUENCER_SEARCH_LIST_SUCCESS = "GET_INFLUENCER_SEARCH_LIST_SUCCESS";

types.DELETE_INFLUENCER_SEARCH_LIST = "DELETE_INFLUENCER_SEARCH_LIST";

types.GET_INFLUENCER_DETAIL = "GET_INFLUENCER_DETAIL";
types.GET_INFLUENCER_DETAIL_SUCCESS = "GET_INFLUENCER_DETAIL_SUCCESS";

types.GET_INFLUENCER_REGIST = "GET_INFLUENCER_REGIST";
types.GET_INFLUENCER_REGIST_SUCCESS = "GET_INFLUENCER_REGIST_SUCCESS";

types.GET_INFLUENCER_EDIT = "GET_INFLUENCER_EDIT";
types.GET_INFLUENCER_EDIT_SUCCESS = "GET_INFLUENCER_EDIT_SUCCESS";

types.SET_INFLUENCER_REGIST = "SET_INFLUENCER_REGIST";
types.SET_INFLUENCER_REGIST_SUCCESS = "SET_INFLUENCER_REGIST_SUCCESS";

types.SET_INFLUENCER_REGIST_SELF = "SET_INFLUENCER_REGIST_SELF";

types.SET_INFLUENCER_FILE_REGIST = "SET_INFLUENCER_FILE_REGIST";
types.SET_INFLUENCER_FILE_REGISTSUCCESS = "SET_INFLUENCER_FILE_REGIST_SUCCESS";

types.SET_INFLUENCER_EDIT = "SET_INFLUENCER_EDIT";
types.SET_INFLUENCER_EDIT_SUCCESS = "SET_INFLUENCER_EDIT_SUCCESS";

types.GET_CANDIDATE_LIST = "GET_CANDIDATE_LIST";
types.GET_CANDIDATE_LIST_SUCCESS = "GET_CANDIDATE_LIST_SUCCESS";

types.SET_CANDIDATE_TO_BLACK = "SET_CANDIDATE_TO_BLACK";
types.SET_CANDIDATE_TO_BLACK_SUCCESS = "SET_CANDIDATE_TO_BLACK_SUCCESS";

types.SET_BLACK_TO_CANDIDATE = "SET_BLACK_TO_CANDIDATE";
types.SET_BLACK_TO_CANDIDATE_SUCCESS = "SET_BLACK_TO_CANDIDATE_SUCCESS";

types.ERROR = "ERROR";

//#region  list
export function* getInfluencerFieldFilter({ payload }) {
  try {
    const fieldFilter = yield call(INFLUENCERAPI.getInfluencerFieldFilter);
    yield put({
      type: types.GET_INFLUENCER_FIELD_FILTER_SUCCESS,
      payload: {
        fieldFilter: fieldFilter,
      },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* getInfluencerSearchList({ payload }) {
  try {
    const influencerList = yield call(
      INFLUENCERAPI.getInfluencerList,
      payload.query,
      payload.limit,
      payload.page,
      payload.order,
      payload.region,
      payload.language,
      payload.minSubscriber,
      payload.maxSubscriber,
      payload.category
    );
    yield put({
      type: types.GET_INFLUENCER_SEARCH_LIST_SUCCESS,
      payload: {
        influencerList: influencerList.data,
        allPage: influencerList.all_pages,
        currentPage: influencerList.now_page,
      },
    });
  } catch (err) {
    // const errCode = err.response.status;
    // yield put({
    //   type: types.ERROR,
    //   payload: { errCode: errCode },
    // });
  }
}

export function* deleteInfluencerSearchList({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const influencerList = yield call(
      INFLUENCERAPI.deleteInfluencerList,
      payload.ids
    );
    yield put({
      type: types.GET_INFLUENCER_SEARCH_LIST_SUCCESS,
      payload: {
        influencerList: influencerList.data,
        allPage: influencerList.all_pages,
        currentPage: influencerList.now_page,
      },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* getInfluencerDetail({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const influencerDetail = yield call(
      INFLUENCERAPI.getInfluencerDetail,
      payload.influencerId
    );
    yield put({
      type: types.GET_INFLUENCER_DETAIL_SUCCESS,
      payload: { influencerDetail: influencerDetail },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}
//#endregion

//#region regist
const infoInit = {
  region: 0,
  language: 0,
  category: 0,
  manager_id: 0,
  gender: 0,
  agency: 0,
  contact: "",
  tag_list: [],
  description: "",
  dedicated_price: 0,
  shout_out_price: 0,
  price_unit: 3,
  reference: null,
  channel_id: "",
};

export function* getInfluencerRegist({ payload }) {
  try {
    const field = yield call(INFLUENCERAPI.getInfluencerField);
    yield put({
      type: types.GET_INFLUENCER_REGIST_SUCCESS,
      payload: { field: field, info: infoInit },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* getInfluencerEdit({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const field = yield call(INFLUENCERAPI.getInfluencerField);
    const info = yield call(
      INFLUENCERAPI.getInfluencerInfo,
      payload.influencerId
    );
    yield put({
      type: types.GET_INFLUENCER_EDIT_SUCCESS,
      payload: { field: field, info: info },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* setInfluencerRegist({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const influencerList = yield call(
      INFLUENCERAPI.postRegistInfluencer,
      payload.data
    );
    yield put({
      type: types.GET_INFLUENCER_SEARCH_LIST_SUCCESS,
      payload: {
        influencerList: influencerList.data,
        allPage: influencerList.all_pages,
        currentPage: influencerList.now_page,
      },
    });
    yield put(setLoadingIndicatorVisibility(false));
    window.alert("등록이 완료되었습니다!");
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* setInfluencerRegistSelf({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    yield call(INFLUENCERAPI.postRegistInfluencer, payload.data);
    yield put(setLoadingIndicatorVisibility(false));
    payload.successCallback();
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* setInfluencerFileRegist({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const influencerList = yield call(
      INFLUENCERAPI.postFileRegistInfluencer,
      payload.file
    );

    yield delay(100);

    yield put({
      type: types.GET_INFLUENCER_SEARCH_LIST_SUCCESS,
      payload: {
        influencerList: influencerList.data,
        allPage: influencerList.all_pages,
        currentPage: influencerList.now_page,
      },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    if (errCode === 400) {
      const errMessage = err.response.data.detail[0];
      window.alert(`excel 에러 : ${errMessage}`);
    }
    yield put(setLoadingIndicatorVisibility(false));
  }
}

export function* setInfluencerEdit({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const influencerList = yield call(
      INFLUENCERAPI.postEditInfluencer,
      payload.influencerId,
      payload.data
    );
    yield put({
      type: types.GET_INFLUENCER_SEARCH_LIST_SUCCESS,
      payload: {
        influencerList: influencerList.data,
        allPage: influencerList.all_pages,
        currentPage: influencerList.now_page,
      },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}
//#endregion

//#region candidate

//#endregion

export function* watchInfluencer() {
  yield takeLeading(
    types.GET_INFLUENCER_FIELD_FILTER,
    getInfluencerFieldFilter
  );
  yield takeLeading(types.GET_INFLUENCER_SEARCH_LIST, getInfluencerSearchList);
  yield takeLeading(
    types.DELETE_INFLUENCER_SEARCH_LIST,
    deleteInfluencerSearchList
  );
  yield takeLeading(types.GET_INFLUENCER_DETAIL, getInfluencerDetail);
  yield takeLeading(types.GET_INFLUENCER_REGIST, getInfluencerRegist);
  yield takeLeading(types.GET_INFLUENCER_EDIT, getInfluencerEdit);
  yield takeLeading(types.SET_INFLUENCER_REGIST, setInfluencerRegist);
  yield takeLeading(types.SET_INFLUENCER_REGIST_SELF, setInfluencerRegistSelf);
  yield takeLeading(types.SET_INFLUENCER_FILE_REGIST, setInfluencerFileRegist);
  yield takeLeading(types.SET_INFLUENCER_EDIT, setInfluencerEdit);
}
