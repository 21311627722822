import { call, delay, put, takeLeading } from "redux-saga/effects";
import { setLoadingIndicatorVisibility } from "store/actions/Common";
import * as CAMPAGINAPI from "../../api/campaign";
import { getFormatDate } from "../../utils/dateUtils";

//#region action type 😃
export const types = {};
//#region list page
types.GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";
types.GET_CAMPAIGN_LIST_SUCCESS = "GET_CAMPAIGN_LIST_SUCCESS";
types.GET_CAMPAIGN_LIST_ERROR = "GET_CAMPAIGN_LIST_ERROR";
//#endregion

//#region info page
types.DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
types.DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
types.DELETE_CAMPAIGN_ERROR = "DELETE_CAMPAIGN_ERROR";

types.GET_FEILD = "GET_FEILD";
types.GET_FEILD_SUCCESS = "GET_FEILD_SUCCESS";
types.GET_FEILD_ERROR = "GET_FEILD_ERROR";

types.GET_CAMPAIGN_INFO = "GET_CAMPAIGN_INFO";
types.GET_CAMPAIGN_INFO_SUCCESS = "GET_CAMPAIGN_INFO_SUCCESS";
types.GET_CAMPAIGN_INFO_ERROR = "GET_CAMPAIGN_INFO_ERROR";

types.GET_REGIST_CAMPAIGN_INFO = "GET_REGIST_CAMPAIGN_INFO";
types.GET_EDIT_CAMPAIGN_INFO = "GET_EDIT_CAMPAIGN_INFO";
types.SET_REGIST_CAMPAIGN_INFO = "SET_REGIST_CAMPAIGN_INFO";
types.SET_REGIST_CAMPAIGN_INFO_SELF = "SET_REGIST_CAMPAIGN_INFO_SELF";
types.SET_EDIT_CAMPAIGN_INFO = "SET_EDIT_CAMPAIGN_INFO";

//#endregion

//#region influencer page
types.GET_CAMPAIGN_INFLUENCER = "GET_CAMPAIGN_INFLUENCER";
types.GET_CAMPAIGN_INFLUENCER_SUCCESS = "GET_CAMPAIGN_INFLUENCER_SUCCESS";
types.GET_CAMPAIGN_INFLUENCER_ERROR = "GET_CAMPAIGN_INFLUENCER_ERROR";

types.SET_CAMPAIGN_INFLUENCER = "SET_CAMPAIGN_INFLUENCER";
types.SET_CAMPAIGN_INFLUENCER_SUCCESS = "SET_CAMPAIGN_INFLUENCER_SUCCESS";
types.SET_CAMPAIGN_INFLUENCER_ERROR = "SET_CAMPAIGN_INFLUENCER_ERROR";

types.DELETE_CAMPAIGN_INFLUENCER = "DELETE_CAMPAIGN_INFLUENCER";
types.DELETE_CAMPAIGN_INFLUENCER_SUCCESS = "DELETE_CAMPAIGN_INFLUENCER_SUCCESS";
types.DELETE_CAMPAIGN_INFLUENCER_ERROR = "DELETE_CAMPAIGN_INFLUENCER_ERROR";

types.SET_CAMPAIGN_INFLUENCER_VIDEO = "SET_CAMPAIGN_INFLUENCER_VIDEO";
types.SET_CAMPAIGN_INFLUENCER_VIDEO_SUCCESS =
  "SET_CAMPAIGN_INFLUENCER_VIDEO_SUCCESS";
types.SET_CAMPAIGN_INFLUENCER_VIDEO_ERROR =
  "SET_CAMPAIGN_INFLUENCER_VIDEO_ERROR";

types.DELETE_CAMPAIGN_INFLUENCER_VIDEO = "DELETE_CAMPAIGN_INFLUENCER_VIDEO";
types.DELETE_CAMPAIGN_INFLUENCER_VIDEO_SUCCESS =
  "DELETE_CAMPAIGN_INFLUENCER_VIDEO_SUCCESS";
types.DELETE_CAMPAIGN_INFLUENCER_VIDEO_ERROR =
  "DELETE_CAMPAIGN_INFLUENCER_VIDEO_ERROR";

//#endregion

types.ERROR = "ERROR";
//#endregion

//#region saga 😃

//#region list page
export function* getCampaignList({ payload }) {
  try {
    const campaignList = yield call(CAMPAGINAPI.getCampaignList);
    yield put({
      type: types.GET_CAMPAIGN_LIST_SUCCESS,
      payload: { campaignList: campaignList },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}
//#endregion

//#region info page
const infoInit = {
  name: "",
  region: 0,
  language: 0,
  platform: 0,
  manager_id: 0,
  advertiser_id: 0,
  status: 0,
  start_date: getFormatDate(new Date()),
  end_date: getFormatDate(new Date()),
  category: 0,
  description: "",
};

//여기
export function* deleteCampaign({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const campaignList = yield call(
      CAMPAGINAPI.deleteCampaign,
      payload.campaignId
    );
    yield put({
      type: types.GET_CAMPAIGN_LIST_SUCCESS,
      payload: { campaignList: campaignList },
    });
    yield put(setLoadingIndicatorVisibility(false));
    window.alert("캠페인이 삭제되었습니다!");
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* getField({ payload }) {
  try {
    const field = yield call(CAMPAGINAPI.getCampaignField);
    yield put({
      type: types.DELETE_CAMPAIGN_SUCCESS,
      payload: { field: field },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* getCampaignInfo({ payload }) {
  //post api 추가
  try {
    const info = yield call(CAMPAGINAPI.getCampaignInfo(payload.campaignId));
    yield put({
      type: types.GET_CAMPAIGN_INFO_SUCCESS,
      payload: { info: info },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//새로운 캠페인 생성시
export function* getRegistCampaignInfo({ payload }) {
  //post api 추가
  try {
    const field = yield call(CAMPAGINAPI.getCampaignField);
    const info = infoInit;
    yield put({
      type: types.GET_FEILD_SUCCESS,
      payload: { field: field },
    });
    yield put({
      type: types.GET_CAMPAIGN_INFO_SUCCESS,
      payload: { info: info },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//여기
export function* setRegistCampaignInfo({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const campaignList = yield call(
      CAMPAGINAPI.postCreateCampaignInfo,
      payload.data
    );
    yield put({
      type: types.GET_CAMPAIGN_LIST_SUCCESS,
      payload: { campaignList: campaignList },
    });
    yield put(setLoadingIndicatorVisibility(false));
    window.alert("캠페인이 등록되었습니다!");
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* setRegistCampaignInfoSelf({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    yield call(CAMPAGINAPI.postCreateCampaignInfo, payload.data);
    yield put(setLoadingIndicatorVisibility(false));
    payload.successCallback();
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//이미 있는 캠페인 수정시
export function* getEditCampaignInfo({ payload }) {
  //post api 추가
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const field = yield call(CAMPAGINAPI.getCampaignField);
    const info = yield call(CAMPAGINAPI.getCampaignInfo, payload.campaignId);
    yield put({
      type: types.GET_FEILD_SUCCESS,
      payload: { field: field },
    });
    yield put({
      type: types.GET_CAMPAIGN_INFO_SUCCESS,
      payload: { info: info },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//여기
export function* setEditCampaignInfo({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const campaignList = yield call(
      CAMPAGINAPI.postEditCampaignInfo,
      payload.campaignId,
      payload.data
    );
    console.log(campaignList);
    yield put({
      type: types.GET_CAMPAIGN_LIST_SUCCESS,
      payload: { campaignList: campaignList },
    });
    yield put(setLoadingIndicatorVisibility(false));
    window.alert("캠페인이 수정되었습니다!");
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//#endregion

//#region influencer page
export function* getCampaignInfluencer({ payload }) {
  //post api 추가
  try {
    const influencers = yield call(
      CAMPAGINAPI.getCampaignInfluecner,
      payload.campaignId
    );
    yield put({
      type: types.GET_CAMPAIGN_INFLUENCER_SUCCESS,
      payload: { influencers: influencers },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//여기
export function* setCampaignInfluencer({ payload }) {
  //post api 추가
  try {
    const influencers = yield call(
      CAMPAGINAPI.postCampaignInfluecner,
      payload.campaignId,
      payload.channelId,
      payload.nickName
    );
    yield put({
      type: types.GET_CAMPAIGN_INFLUENCER_SUCCESS,
      payload: { influencers: influencers },
    });
    window.alert("인플루언서가 등록되었습니다!");
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//여기
export function* deleteCampaignInfluencer({ payload }) {
  //post api 추가
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const influencers = yield call(
      CAMPAGINAPI.deleteCampaignInfluecner,
      payload.campaignId,
      payload.channelId
    );
    yield put({
      type: types.GET_CAMPAIGN_INFLUENCER_SUCCESS,
      payload: { influencers: influencers },
    });
    yield put(setLoadingIndicatorVisibility(false));
    window.alert("인플루언서가 삭제되었습니다!");
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//여기
export function* setCampaignInfluencerVideo({ payload }) {
  //post api 추가
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const influencers = yield call(
      CAMPAGINAPI.postCampaignInfluecnerContents,
      payload.campaignId,
      payload.channelId,
      payload.videoId
    );

    yield put({
      type: types.GET_CAMPAIGN_INFLUENCER_SUCCESS,
      payload: { influencers: influencers },
    });
    yield put(setLoadingIndicatorVisibility(false));
    window.alert("비디오가 등록되었습니다!");
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//여기
export function* deleteCampaignInfluecnerContents({ payload }) {
  //post api 추가
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const influencers = yield call(
      CAMPAGINAPI.deleteCampaignInfluecnerContents,
      payload.campaignId,
      payload.channelId,
      payload.videoId
    );
    yield put({
      type: types.GET_CAMPAIGN_INFLUENCER_SUCCESS,
      payload: { influencers: influencers },
    });
    yield put(setLoadingIndicatorVisibility(false));
    window.alert("컨텐츠가 삭제되었습니다!");
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

//#endregion
//#endregion

export function* watchCampaign() {
  //#region list page
  yield takeLeading(types.GET_CAMPAIGN_LIST, getCampaignList);
  //#endregion
  //#region info page
  yield takeLeading(types.DELETE_CAMPAIGN, deleteCampaign);
  yield takeLeading(types.GET_REGIST_CAMPAIGN_INFO, getRegistCampaignInfo);
  yield takeLeading(types.SET_REGIST_CAMPAIGN_INFO, setRegistCampaignInfo);
  yield takeLeading(
    types.SET_REGIST_CAMPAIGN_INFO_SELF,
    setRegistCampaignInfoSelf
  );
  yield takeLeading(types.GET_EDIT_CAMPAIGN_INFO, getEditCampaignInfo);
  yield takeLeading(types.SET_EDIT_CAMPAIGN_INFO, setEditCampaignInfo);
  //#endregion
  //#region influencer page
  yield takeLeading(types.GET_CAMPAIGN_INFLUENCER, getCampaignInfluencer);
  yield takeLeading(types.SET_CAMPAIGN_INFLUENCER, setCampaignInfluencer);
  yield takeLeading(types.DELETE_CAMPAIGN_INFLUENCER, deleteCampaignInfluencer);
  yield takeLeading(
    types.SET_CAMPAIGN_INFLUENCER_VIDEO,
    setCampaignInfluencerVideo
  );
  yield takeLeading(
    types.DELETE_CAMPAIGN_INFLUENCER_VIDEO,
    deleteCampaignInfluecnerContents
  );
  //#endregion
}
