import { types } from "store/sagas/SettingField";
const initialState = [
  {
    type: "category",
    data: ["게임"],
  },
  {
    type: "platform",
    data: ["유튜브", "페이스북"],
  },
  {
    type: "agency",
    data: ["탑노트컴퍼니"],
  },
  {
    type: "tag",
    data: ["데일리룩"],
  },
];

const settingFieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SETTING_FIELD_LIST_SUCCESS:
      return action.payload.fieldList;

    default:
      return state;
  }
};

export default settingFieldReducer;
