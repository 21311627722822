import { types } from "store/sagas/SettingCrawling";
import fieldInit from "data/setting/crawlingField";
import crawlingInit from "data/setting/crawling";
const initialState = {
  fieldList: fieldInit,
  crawlingList: crawlingInit,
};

const settingCrawlingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SETTING_CRAWLING_LIST_SUCCESS:
      return {
        ...state,
        fieldList: action.payload.fieldList,
        crawlingList: action.payload.crawlingList,
      };
    case types.SET_SETTING_CRAWLING_SUCCESS:
      return {
        ...state,
        crawlingList: action.payload.crawlingList,
      };
    case types.DELETE_SETTING_CRAWLING_SUCCESS:
      return {
        ...state,
        crawlingList: action.payload.crawlingList,
      };

    default:
      return state;
  }
};

export default settingCrawlingReducer;
