import axios from "axios";
import { HOST } from "./host";
export const getSettingFieldList = async () => {
  const response = await axios.get(`https://${HOST}/field`);
  return response.data;
};
export const postSettingFieldListItem = async (type, item) => {
  const response = await axios.post(`https://${HOST}/field/${type}`, {
    name: item,
  });
  return response.data;
};
export const deleteSettingFieldListItem = async (type, item) => {
  const response = await axios.delete(`https://${HOST}/field/${type}`, {
    data: { name: item },
  });
  return response.data;
};

export const getSettingCrawlingField = async () => {
  const response = await axios.get(`https://${HOST}/crawler/fields`);
  return response.data;
};
export const getSettingCrawlingList = async () => {
  const response = await axios.get(`https://${HOST}/crawler`);
  return response.data;
};
export const setSettingCrawling = async (
  keyword,
  regionId,
  quantity,
  isImmediately,
  minSubscriber,
  maxSubscriber,
  startDate
) => {
  const response = await axios.post(`https://${HOST}/crawler`, {
    keyword: keyword,
    region_id: regionId,
    daily_limit: quantity,
    is_immediately: isImmediately,
    min_subscriber: minSubscriber,
    max_subscriber: maxSubscriber,
    crawl_date: startDate,
  });
  return response.data;
};
export const deleteSettingCrawling = async (id) => {
  const response = await axios.delete(`https://${HOST}/crawler/${id}`);
  return response.data;
};
