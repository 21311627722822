import React, { useEffect, useState } from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const isInclude = (currentPath, path) => {
  return currentPath.includes(path);
};

const Header = ({ location }) => {
  const user = useSelector((state) => state.user);
  const isManager = user.roll === "MANAGER" ? true : false;
  const dispatch = useDispatch();

  const logout = (e) => {
    dispatch({ type: "LOGOUT" });
  };

  return (
    <div
      className="header"
      style={{
        display:
          isInclude(location.pathname, "/intro") ||
          isInclude(location.pathname, "/influencer-regist-self") ||
          isInclude(location.pathname, "/campaign-regist-self") ||
          isInclude(location.pathname, "/success") ||
          location.pathname === "/"
            ? "none"
            : "block",
      }}
    >
      <div className="nav-bar">
        <Link to="/intro">
          <h1 className="logo">globlin</h1>
        </Link>
        <ul>
          <li style={{ display: isManager ? "" : "none" }}>
            <NavLink
              to="/influencer/list"
              className={
                isInclude(location.pathname, "/influencer") ? "active" : ""
              }
            >
              Influencer
            </NavLink>
            <div
              className={
                isInclude(location.pathname, "/influencer")
                  ? "depth"
                  : "depth fadeout"
              }
            >
              <NavLink to="/influencer/list" activeClassName="active">
                목록
              </NavLink>
              <NavLink to="/influencer/regist" activeClassName="active">
                등록
              </NavLink>
              <NavLink to="/influencer/candidate" activeClassName="active">
                후보목록
              </NavLink>
              <NavLink to="/influencer/black" activeClassName="active">
                블랙목록
              </NavLink>
            </div>
          </li>
          <li>
            <NavLink
              to="/campaign/list"
              className={
                isInclude(location.pathname, "/campaign") ? "active" : ""
              }
            >
              Campaign
            </NavLink>
            <div
              className={
                isInclude(location.pathname, "/campaign")
                  ? "depth"
                  : "depth fadeout"
              }
            >
              <NavLink to="/campaign/list" activeClassName="active">
                목록
              </NavLink>
              <NavLink
                to="/campaign/regist-info"
                activeClassName="active"
                style={{ display: isManager ? "" : "none" }}
              >
                등록
              </NavLink>
            </div>
          </li>
          <li>
            <NavLink to="/report" activeClassName="active">
              Report
            </NavLink>
          </li>
          <li style={{ display: isManager ? "" : "none" }}>
            <NavLink
              to="/setting/crawling"
              className={
                isInclude(location.pathname, "/setting") ? "active" : ""
              }
            >
              Setting
            </NavLink>
            <div
              className={
                isInclude(location.pathname, "/setting")
                  ? "depth"
                  : "depth fadeout"
              }
            >
              <NavLink to="/setting/crawling" activeClassName="active">
                크롤링
              </NavLink>
              <NavLink to="/setting/field" activeClassName="active">
                필드
              </NavLink>
            </div>
          </li>
        </ul>
        {user.isLogged && (
          <div className="log-box">
            <span>{user.id}</span>
            <a>
              <button type="button" onClick={logout}>
                로그아웃
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Header);
