import { ConnectContactLens } from "aws-sdk";
import axios from "axios";
import { HOST } from "./host";

// const JWT_EXPIRY_TIME = 3 * 3600 * 1000; // 만료 시간 (24시간 밀리 초로 표현)
const REFRESH_TOKEN = "refreshToken";

export const onLogin = async (payload) => {
  const formData = new FormData();
  formData.append("username", payload.username);
  formData.append("password", payload.password);

  const response = await axios.post(`https://${HOST}/user/login`, formData);
  return response.data;
};

export const onRefresh = async () => {
  //refresh data
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
  const response = await axios.post(`https://${HOST}/user/refresh`, null, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
  return response.data;
};

export const onLogout = () => {
  console.log("log  out");
  axios.defaults.headers.common["Authorization"] = "";
  deleteLocalStorage();
};

export const setAccessToken = (accessToken) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

export const setRefreshToken = (refreshToken) => {
  saveLocalStorage(refreshToken);
};

const saveLocalStorage = (refreshToken) => {
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

const deleteLocalStorage = () => {
  window.localStorage.removeItem(REFRESH_TOKEN);
};
