import moment from "moment-timezone";

export const getProcessDate = (raw) => {
  const result = moment(`${raw}Z`).tz("Asia/Seoul").format("YY-MM-DD");
  return result;
};

export const getFormatDate = (date) => {
  if (!date) return new Date();
  const year = date.getFullYear();
  let month = 1 + date.getMonth();
  month = month >= 10 ? month : "0" + month;
  let day = date.getDate();
  day = day >= 10 ? day : "0" + day;
  return year + "-" + month + "-" + day;
};

export const getPeriod = (startDate, endDate) => {
  const result = `${startDate} ~ ${endDate}`;
  return result;
};
