import { call, delay, put, takeLeading } from "redux-saga/effects";
import * as SETTINGAPI from "../../api/setting";
import { setLoadingIndicatorVisibility } from "store/actions/Common";

export const types = {};
types.GET_SETTING_FIELD_LIST = "GET_SETTING_FIELD_LIST";
types.GET_SETTING_FIELD_LIST_SUCCESS = "GET_SETTING_FIELD_LIST_SUCCESS";

types.SET_SETTING_FIELD_ITEM = "SET_SETTING_FIELD_ITEM";
types.SET_SETTING_FIELD_ITEM_SUCCESS = "SET_SETTING_FIELD_ITEM_SUCCESS";

types.DELETE_SETTING_FIELD_ITEM = "DELETE_SETTING_FIELD_ITEM";
types.DELETE_SETTING_FIELD_ITEM_SUCCESS = "DELETE_SETTING_FIELD_ITEM_SUCCESS";

types.ERROR = "ERROR";

export function* getSettingFieldList({ payload }) {
  try {
    const fieldList = yield call(() => SETTINGAPI.getSettingFieldList());
    yield put({
      type: types.GET_SETTING_FIELD_LIST_SUCCESS,
      payload: { fieldList: fieldList },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* setSettingFieldListItem({ payload }) {
  try {
    const fieldList = yield call(() =>
      SETTINGAPI.postSettingFieldListItem(payload.type, payload.name)
    );
    yield put({
      type: types.GET_SETTING_FIELD_LIST_SUCCESS,
      payload: { fieldList: fieldList },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* deleteSettingFieldListItem({ payload }) {
  try {
    const fieldList = yield call(() =>
      SETTINGAPI.deleteSettingFieldListItem(payload.type, payload.name)
    );
    console.log(payload);
    yield put({
      type: types.GET_SETTING_FIELD_LIST_SUCCESS,
      payload: { fieldList: fieldList },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* watchSettingField() {
  yield takeLeading(types.GET_SETTING_FIELD_LIST, getSettingFieldList);
  yield takeLeading(types.SET_SETTING_FIELD_ITEM, setSettingFieldListItem);
  yield takeLeading(
    types.DELETE_SETTING_FIELD_ITEM,
    deleteSettingFieldListItem
  );
}
