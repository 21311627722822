import axios from "axios";
import { HOST } from "./host";

//list

export const getInfluencerFieldFilter = async (ids) => {
  const response = await axios.get(`https://${HOST}/influencer/field/filter`);
  return response.data;
};

export const getInfluencerList = async (
  query,
  limit,
  page,
  order,
  region,
  language,
  minSubscriber,
  maxSubscriber,
  category
) => {
  console.log(region, language, minSubscriber, maxSubscriber, category);
  const response =
    region == undefined &&
    language == undefined &&
    minSubscriber == undefined &&
    maxSubscriber == undefined &&
    category == undefined
      ? await axios.get(
          `https://${HOST}/influencer?q=${query}&limit=${limit}&skip=${page}&order=${order}`
        )
      : await axios.get(
          `https://${HOST}/influencer?q=${query}&limit=${limit}&skip=${page}&order=${order}&region=${region}&language=${language}&min_subscriber=${minSubscriber}&max_subscriber=${maxSubscriber}&category=${category}`
        );
  return response.data;
};

export const deleteInfluencerList = async (ids) => {
  const response = await axios.delete(
    `https://dev-api.globlin.io/influencer?ids=${ids}`
  );
  return response.data;
};

export const getInfluencerDetail = async (influencerId) => {
  const response = await axios.get(
    `https://${HOST}/influencer/${influencerId}/detail`
  );
  return response.data;
};

//regist
export const getInfluencerField = async () => {
  const response = await axios.get(`https://${HOST}/influencer/field`);
  return response.data;
};

export const getInfluencerInfo = async (influencerId, data) => {
  const response = await axios.get(
    `https://${HOST}/influencer/${influencerId}`
  );
  return response.data;
};

export const postRegistInfluencer = async (data) => {
  const response = await axios.post(`https://${HOST}/influencer`, data);
  return response.data;
};

export const postEditInfluencer = async (influencerId, data) => {
  const response = await axios.post(
    `https://${HOST}/influencer/${influencerId}`,
    data
  );
  return response.data;
};

export const postFileRegistInfluencer = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  const response = await axios.post(
    `https://${HOST}/influencer/upload`,
    formData
  );
  return response.data;
};

//candidate
export const getCandidateList = async (
  keyword,
  isBlack,
  regionId,
  minSubscirber,
  maxSubscriber,
  startDate,
  endDate,
  limit,
  page,
  order
) => {
  const response = await axios.get(
    `https://${HOST}/influencer/crawled?is_blacked=${isBlack}&region_id=${regionId}&min_subscriber=${minSubscirber}&max_subscriber=${maxSubscriber}&start_date=${startDate}&end_date=${endDate}&q=${keyword}&limit=${limit}&skip=${page}&order=${order}`
  );
  return response.data;
};

export const setCandidateToBlack = async (ids) => {
  const response = await axios.post(
    `https://${HOST}/influencer/crawled/black?ids=${ids}`
  );
  return response.data;
};

export const setBlackToCandidate = async (ids) => {
  const response = await axios.post(
    `https://${HOST}/influencer/crawled/restore?ids=${ids}`
  );
  return response.data;
};
