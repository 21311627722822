import { types } from "store/sagas/Influencer";
import CandidateListInit from "data/influencer/candidate";

const initialState = {
  candidateList: CandidateListInit.data,
  allPage: CandidateListInit.all_pages,
  currentPage: CandidateListInit.now_page,
};

const influencerCandidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CANDIDATE_LIST_SUCCESS:
      return {
        ...state,
        candidateList: action.payload.candidateList,
        allPage: action.payload.allPage,
        currentPage: action.payload.currentPage,
      };
    default:
      return state;
  }
};

export default influencerCandidateReducer;
