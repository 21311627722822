import React, { memo, useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import routes from "./Routes";
import Header from "components/Header";
import Footer from "components/Footer";
import LoadingIndicator from "components/LoadingIndicator";
import "react-datepicker/dist/react-datepicker.css";
import "css/datepicker.css";
import "css/datepickerForDetail.css";
import "css/datepickerForArrange.css";
import { setUser } from "store/actions/User";

const LoginChecker = memo(({ children }) => {
  const user = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const allowPaths = [
    "/",
    "/intro",
    "/login",
    "/influencer-regist-self",
    "/campaign-regist-self",
    "/success",
  ];
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setUser());
  }, []);

  // 도메인이 바뀔때마다 확인해서 리다이렉션
  useEffect(() => {
    if (!user.isLogged && !allowPaths.includes(pathname)) {
      history.push("/login");
    }
  }, [user, allowPaths]);

  return children;
});

function App() {
  return (
    <Router>
      <Header />
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <LoginChecker>
            {routes.map((r) => (
              <Route
                key={r.path}
                path={r.path}
                component={r.component}
                strict
                exact={r.isExact}
              />
            ))}
          </LoginChecker>
        </Switch>
      </Suspense>
      <Footer />
      <LoadingIndicator />
    </Router>
  );
}

export default App;
