import { types } from "store/sagas/User";

export const login = (payload) => ({
  type: types.LOGIN,
  payload,
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const setUser = () => ({
  type: types.SET_USER,
});
