import { all } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";
import { watchLogin } from "store/sagas/User";
import { watchCampaign } from "store/sagas/Campaign";
import { watchInfluencer } from "store/sagas/Influencer";
import { watchInfluencerCandidate } from "store/sagas/InfluencerCandidate";
import { watchReportList } from "store/sagas/Report";
import { watchError } from "store/sagas/Error";
import { watchSettingField } from "store/sagas/SettingField";
import { watchSettingCrawling } from "store/sagas/SettingCrawling";

export function* rootSaga() {
  yield all([
    watchLogin(),
    watchCampaign(),
    watchInfluencer(),
    watchInfluencerCandidate(),
    watchReportList(),
    watchError(),
    watchSettingField(),
    watchSettingCrawling(),
  ]);
}

export default createSagaMiddleware();
