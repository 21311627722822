import { call, put, takeLeading, select } from "redux-saga/effects";
import { setLoadingIndicatorVisibility } from "store/actions/Common";
import * as INFLUENCERAPI from "api/influencer";

export const types = {};
types.GET_CANDIDATE_LIST = "GET_CANDIDATE_LIST";
types.GET_CANDIDATE_LIST_SUCCESS = "GET_CANDIDATE_LIST_SUCCESS";

types.SET_CANDIDATE_TO_BLACK = "SET_CANDIDATE_TO_BLACK";
types.SET_CANDIDATE_TO_BLACK_SUCCESS = "SET_CANDIDATE_TO_BLACK_SUCCESS";

types.SET_BLACK_TO_CANDIDATE = "SET_BLACK_TO_CANDIDATE";
types.SET_BLACK_TO_CANDIDATE_SUCCESS = "SET_BLACK_TO_CANDIDATE_SUCCESS";

types.ERROR = "ERROR";

export function* getCandidateList({ payload }) {
  try {
    const candidateList = yield call(
      INFLUENCERAPI.getCandidateList,
      payload.keyword,
      payload.isBlack,
      payload.regionId,
      payload.minSubscriber,
      payload.maxSubscriber,
      payload.startDate,
      payload.endDate,
      payload.limit,
      payload.page,
      payload.order
    );
    yield put({
      type: types.GET_CANDIDATE_LIST_SUCCESS,
      payload: {
        candidateList: candidateList.data,
        allPage: candidateList.all_pages,
        currentPage: candidateList.now_page,
      },
    });
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* setCandidateToBlack({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const candidateList = yield call(
      INFLUENCERAPI.setCandidateToBlack,
      payload.ids
    );
    yield put({
      type: types.GET_CANDIDATE_LIST_SUCCESS,
      payload: {
        candidateList: candidateList.data,
        allPage: candidateList.all_pages,
        currentPage: candidateList.now_page,
      },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* setBlackToCandidate({ payload }) {
  try {
    yield put(setLoadingIndicatorVisibility(true));
    const candidateList = yield call(
      INFLUENCERAPI.setBlackToCandidate,
      payload.ids
    );
    yield put({
      type: types.GET_CANDIDATE_LIST_SUCCESS,
      payload: {
        candidateList: candidateList.data,
        allPage: candidateList.all_pages,
        currentPage: candidateList.now_page,
      },
    });
    yield put(setLoadingIndicatorVisibility(false));
  } catch (err) {
    const errCode = err.response.status;
    yield put({
      type: types.ERROR,
      payload: { errCode: errCode },
    });
  }
}

export function* watchInfluencerCandidate() {
  yield takeLeading(types.GET_CANDIDATE_LIST, getCandidateList);
  yield takeLeading(types.SET_CANDIDATE_TO_BLACK, setCandidateToBlack);
  yield takeLeading(types.SET_BLACK_TO_CANDIDATE, setBlackToCandidate);
}
